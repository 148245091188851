import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  & > h1:first-child {
    font-size: 80px;
  }
  h1,
  p {
    text-align: center;
  }
`

const NotFoundPage = () => (
  <Layout contentCentered>
    <SEO title="404: No encontrado" />
    <Container>
      <h1>404</h1>
      <h1>No encontrado</h1>
      <p>
        No se ha encontrado el recurso. Ir al{" "}
        <Link to="/" className="">
          inicio
        </Link>
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
